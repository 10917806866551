export const Localization = {
  logoFilename: "Logo TVS.svg",
  clientName: "TVSC",
  navbarBackgroundColor: "#34276E",
  PRIVACY_POLICY_URL: 'https://www.tvsd.ai/en/privacy-policy',
  TERMS_OF_USE_URL: 'https://www.tvsd.ai/en/terms-of-use',
  COOKIE_POLICY_URL: 'https://www.tvsd.ai/en/cookie-policy',
  eulaTextUrl: 'https://tvsd-assets.s3-ap-southeast-1.amazonaws.com/eula/EULA_LOS_webapp.txt',
  eulaVersionUrl: 'https://tvsd-assets.s3-ap-southeast-1.amazonaws.com/eula/EULA_LOS_webapp.json',
  privacyStatementUrl:
    'https://tvsd-assets.s3.ap-southeast-1.amazonaws.com/privacy-statement/mobile_application_privacy_statement.txt',
  privacyStatementVersion:
    'https://tvsd-assets.s3.ap-southeast-1.amazonaws.com/privacy-statement/mobile_application_privacy_statement.json',
};
