import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router";
import { Button, ListGroup, ListGroupItem } from "reactstrap";
import styled from "styled-components";
import { element } from "prop-types";
import { AppContext } from "../../helpers";
import { Link } from "react-router-dom";
import { Localization } from "../../constant/config";

const Sidebar = ({ size, setShowSidebar }: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { auth } = useSelector((state: any) => state);
  const history = useHistory();

  // Submenu toggle handler
  const toggleSubMenu = (event) => {};

  const handleLogout = () => {
    history.push("/login");
  };

  const menus = (() => {
    const items: any = [];
    // items.push({
    //   name: "Dashboard",
    //   href: "/dashboard",
    //   link: "/dashboard",
    //   icon: "icon_dashboard.svg",
    // });
    items.push({
      name: "Loans",
      href: "/loans",
      link: "/loans",
      icon: "icon_loans.svg",
    });
    // items.push({
    //   name: "Tasks",
    //   href: "/task",
    //   link: "/task",
    //   icon: "clock.png",
    // });
    // items.push({
    //   name: "New Application",
    //   href: "/step1-application",
    //   link: "/step1-application",
    //   icon: "icon_loans.svg",
    // });
    return items;
  })();

  const renderNavMenu = (el: any) => {
    return (
      <div className="">
        <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
          {el?.icon && (
            // eslint-disable-next-line
            <img
              // className="align-self-center"
              src={`${process.env.PUBLIC_URL}/images/${el.icon}`}
              alt="icon"
              width="28"
              height="28"
              onClick={() => toggleSubMenu(el)}
            />
          )}
          {!el?.link ? (
            // eslint-disable-next-line
            <div
              className="ml-3 align-self-center"
              onClick={() => {
                toggleSubMenu(el);
              }}
              aria-hidden="true"
            >
              {el.name}
            </div>
          ) : (
            <SideBtn
              onClick={(event) => {
                // event.stopPropagation();
                // if (["md", "s", "xs"].includes(size.screen))
                // setShowSidebar(false);
                // history.push(el.link);
              }}
            >
              {el.name}
            </SideBtn>
          )}
        </div>
        {renderArrow(el, el?.items, el?.name)}
      </div>
    );
  };

  const renderArrow = (el, items, name) => {
    if (items) {
      return (
        <div
          className="table-arrow-down align-self-center"
          onClick={() => toggleSubMenu(el)}
          aria-hidden="true"
        />
      );
    }
    return <></>;
  };

  const route = useRouteMatch();

  const activeRoute = route.path === "/" ? "/dashboard" : route.path;
  return (
    <SidebarComponent style={{ paddingTop: "3%" }}>
      <div style={{ textAlign: "center", overflowY: "scroll" }}>
        <Link to="/">
          <img
            src={`${process.env.PUBLIC_URL}/images/${Localization.logoFilename}`}
            alt={Localization.clientName}
            height="28"
            width="29%"
            className="mt-3"
          />
        </Link>
        <ListGroupStyle style={{ paddingTop: "12%" }}>
          {menus?.map((el: any) => (
            <ListGroupItem
              tag={el.href ? "a" : "div"}
              href={el?.href}
              key={el.name}
              className={`parent ${
                activeRoute.includes(el.link) ? "is-active" : ""
              }`}
            >
              {renderNavMenu(el)}
              {
                el?.items && (
                  <ListGroupStyle>
                    {el?.items?.map((child: any, index: number) => (
                      <ListGroupItem
                        key={child.href}
                        className={`sub ${
                          activeRoute.includes(child.link) ? "is-active" : ""
                        }`}
                        style={{}}
                      >
                        <ul style={{ paddingLeft: "20px" }}>
                          <li
                            style={{
                              paddingTop: "12px",
                              paddingBottom: "12px",
                              listStyle: "disc",
                              color: "#999999",
                            }}
                          >
                            {renderNavMenu(child)}
                          </li>
                        </ul>
                      </ListGroupItem>
                    ))}
                  </ListGroupStyle>
                )
                // eslint-disable-next-line @typescript-eslint/indent
              }
            </ListGroupItem>
          ))}
        </ListGroupStyle>
      </div>
      <ListGroupStyle>
        <div>
          <div className="d-flex justify-content-center mb-2">
            <FooterText className="mr-2">Powered by</FooterText>
            <img
              className="d-flex align-self-start"
              src={`${process.env.PUBLIC_URL}/images/TVSDigital_logo_72x26.svg`}
              alt="TVS Digital"
            />
          </div>
        </div>
        <Hrline />
        <div className="d-flex justify-content-around mb-3 ml-3 mr-3">
          <LegalLink href={Localization.PRIVACY_POLICY_URL} target="_blank">
            Privacy Policy
          </LegalLink>
          <LegalLink href={Localization.TERMS_OF_USE_URL} target="_blank">
            Terms of use
          </LegalLink>
          <LegalLink href={Localization.COOKIE_POLICY_URL} target="_blank">
            Cookie Policy
          </LegalLink>
        </div>
      </ListGroupStyle>
    </SidebarComponent>
  );
};
const Hrline = styled.hr`
  border: 0;
  border-bottom: 1px solid #dddddd;
  display: block;
  width: 90%;
`;
const SidebarComponent = styled.div`
  box-shadow: 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-color: #fff;
  width: 250px;
  position: fixed;
  height: calc(100vh - 11px);
  @media (max-width: 820px) {
    position: static;
    width: 100%;
    flex: 0 0 100%;
  }
`;
const FooterWrapper = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: flex-start;
  padding: 0px 7%;
`;
const ListGroupStyle = styled(ListGroup)`
  .list-group {
    margin: 0;
  }
  .list-group-item {
    background-color: transparent;
    padding: 0;
    border-top: none;
    border-right: none;
    border-bottom: none;
    border-left: 1px solid transparent;
    margin-left: -1px;
    .btn {
      font-weight: 500;
      color: #333333;
    }
    &.sub {
      padding-left: 27px;
      .btn {
        font-size: 14px;
        font-weight: 500;
        color: #333333;
      }
      &.is-active {
        a,
        .btn {
          font-size: 14px;
          font-weight: 500;
          color: #333333;
        }
        border-radius: 0px 32px 32px 0px;
        background-color: #e4e4e4;
        margin-right: 8px;
      }
    }
    > div {
      padding: 15px;
      font-weight: 500;
      color: #333333;
      align-self: center;
    }
    &.parent.is-active {
      > div {
        border-radius: 0px 32px 32px 0px;
        background-color: #e4e4e4;
        margin-right: 8px;
      }
    }
  }
`;

const SideBtn = styled(Button)`
  border: none;
  background-color: transparent !important;
  font-size: 14px;
  text-decoration: none;
  &:hover {
    text-decoration: none !important;
  }
`;

const FooterText = styled.div`
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #333333;
`;
const LegalLink = styled.a`
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
`;
export default Sidebar;
