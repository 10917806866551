import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./app/App";
import reportWebVitals from "./reportWebVitals";
import { ConnectedRouter } from "connected-react-router";
import history from "utils/history";
import configureStore from "redux/configureStore";
import { Provider } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';

const store = configureStore({}, history);

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
